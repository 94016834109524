const firstStep = {
  trips: [
    {
      id: 1739964351743,
      from: {
        address_components: [
          {
            long_name: "California",
            short_name: "CA",
            types: ["administrative_area_level_1", "political"],
          },
          {
            long_name: "United States",
            short_name: "US",
            types: ["country", "political"],
          },
        ],
        formatted_address: "California, USA",
        geometry: {
          location: {
            lat: 36.778261,
            lng: -119.4179324,
          },
          viewport: {
            south: 32.52883196043871,
            west: -124.4820030490848,
            north: 42.00950300443976,
            east: -114.1312110028529,
          },
        },
        name: "California",
        html_attributions: [],
        invalid: false,
        errorMessage: "",
      },
      to: {
        address_components: [
          {
            long_name: "Calabasas Trail",
            short_name: "Calabasas Trail",
            types: ["route"],
          },
          {
            long_name: "San Luis Obispo County",
            short_name: "San Luis Obispo County",
            types: ["administrative_area_level_2", "political"],
          },
          {
            long_name: "California",
            short_name: "CA",
            types: ["administrative_area_level_1", "political"],
          },
          {
            long_name: "United States",
            short_name: "US",
            types: ["country", "political"],
          },
          {
            long_name: "93453",
            short_name: "93453",
            types: ["postal_code"],
          },
        ],
        formatted_address: "Calabasas Trail, California 93453, USA",
        geometry: {
          location: {
            lat: 35.2966259,
            lng: -119.902731,
          },
          viewport: {
            south: 35.29527691970848,
            west: -119.9040799802915,
            north: 35.29797488029148,
            east: -119.9013820197085,
          },
        },
        name: "Calabasas Trail",
        html_attributions: [],
        invalid: false,
        errorMessage: "",
      },
    },
  ],
  boxes: [
    {
      id: 1739964351743,
      length: 49,
      label: "",
      width: 10,
      height: 33,
      weight: 60,
      marketValue: "550",
      preset: "TRI ALL 3 Clam Shell Bike Case",
      isEbike: false,
      isExact: false,
      linearUnit: "in",
      weightUnit: "lb",
      sortOrder: "",
      contents: {
        id: 2,
        name: "Bicycle Frame",
        sortOrder: 5,
        description: null,
        isActive: true,
        code: 8001,
        percentageBelow: 43,
        size: 135,
        percentageAbove: 53,
        markingType: "MarkUp",
      },
      premiumProtection: {
        id: 2,
        name: "$500 Coverage & Delivery Signature (+$4.80)",
        coverage: 500,
        cost: 4.8,
        isActive: true,
        description: null,
      },
    },
  ],
  purpose: "",
};

const secondStep = {
  stages: [
    {
      stageNo: "Stage 1",
      shipmentDate: "2025-02-28T08:00:00.000Z",
      hasPickup: false,
      pickup: {
        name: "Drop off at a UPS Store or Customer Center (NO CHARGE)",
        value: 2,
      },
      hasDropoff: false,
      dropoff: null,
      services: [
        {
          id: 457,
          serviceTypeCode: "01",
          serviceDescription: "Next Day Air",
          totalPrice: 2386.9071,
          isSelected: false,
        },
        {
          id: 458,
          serviceTypeCode: "02",
          serviceDescription: "2nd Day Air",
          totalPrice: 1207.8738,
          isSelected: true,
        },
        {
          id: 459,
          serviceTypeCode: "03",
          serviceDescription: "Ground",
          totalPrice: 630.4212,
          isSelected: false,
        },
      ],
      pickupType: null,
      serviceDate: null,
      pickupDate: null,
      readyAt: null,
      closeAt: null,
    },
  ],
};

const orderObject = {
  transaction_id: 114,
  currency: "USD",
  value: "1221.18",
  travel_type: "One-Way",
  items: [
    {
      item_id: "Packages",
      price: 1200.38,
      quantity: 1,
    },
    {
      item_id: "Protection",
      price: 20.8,
      quantity: 1,
    },
    {
      item_id: "Drop Off",
      price: 0,
      quantity: 0,
    },
  ],
};

/**
 * Send a Google Tag Manager event, with ecommerce data.
 *
 * @param {string} eventName The name of the event to send.
 * @param {Object} data The ecommerce data to send.
 */
export const sendGTMEvent = (eventName, data) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    ecommerce: data,
  });
  if (window.gtag) {
    window.gtag('event', eventName, data);
  } else {
    console.warn('gtag is not defined');
  }
};

/**
 * Generate GTM items array from the boxes data.
 *
 * @param {Array} boxes The boxes array containing shipment and insurance details.
 * @param {number} shippingPrice The price of the selected shipping service.
 * @returns {Array} Formatted items array for GTM.
 */
const generateItems = (boxes, shippingPrice = 0) => {
  if (!boxes || !Array.isArray(boxes)) return [];

  // 1. Create shipping items.
  const shippingItems = boxes.map((box) => ({
    item_id: "Shipping",
    item_name: "Shipping",
    item_category: box?.contents?.name || "Unknown",
    price: Number(shippingPrice ? shippingPrice.toFixed(2) : 0),
    market_value: Number(box?.marketValue),
    quantity: 1,
    length: box?.length,
    width: box?.width,
    height: box?.height,
    weight: box?.weight,
    case_model: box?.preset,
  }));

  // 2. Create insurance items.
  const insuranceItems = boxes
    .filter((box) => box?.premiumProtection)
    .map((box) => ({
      item_id: "Insurance",
      item_name: "Insurance",
      item_category: box?.premiumProtection?.name,
      price: box?.premiumProtection?.cost,
      coverage: box?.premiumProtection?.coverage,
      quantity: 1,
    }));

  // 3. Return combined items array.
  return [...shippingItems, ...insuranceItems];
};

export const beginCheckoutLayer = (data) => {
  if (!data) return;
  try {
    // 1. Generate items array.
    const items = generateItems(data?.boxes);
    // 2. Construct ecommerce data object.
    const ecommerceData = {
      currency: "USD",
      value: 0, // Order value is 0 at begin_checkout as specified.
      items: items,
    };
    // 3. Send the GTM event.
    sendGTMEvent("begin_checkout", ecommerceData);
    //console.log("begin_checkout event sent:", ecommerceData);
  } catch (error) {
    console.log(error);
  }
};

/**
 * Push GTM data layer event for "add_shipping_info".
 *
 * @param {Object} stages The stages array containing shipment details.
 * @param {Object} boxes The boxes array containing package details.
 */
export const shippingInfoLayer = (shipping, data) => {
  try {
    if (!shipping || !Array.isArray(shipping?.stages) || !data) return;

    // Find the selected shipping service
    const selectedService = shipping?.stages[0]?.services?.find(
      (service) => service.isSelected
    );

    if (!selectedService) {
      console.warn("No selected shipping service found.");
      return;
    }
    // Extract shipping details
    const shippingTier = selectedService.serviceDescription;
    const shippingPrice = selectedService.totalPrice;

    // Calculate total premium protection cost
    const totalProtectionCost = data?.boxes.reduce(
      (sum, box) => sum + (box?.premiumProtection?.cost || 0),
      0
    );
    // Calculate total value (shipping price + insurance cost)
    const totalValue = shippingPrice + totalProtectionCost;

    // Generate items with the shipping price
    const items = generateItems(data?.boxes, shippingPrice);

    const ecommerceData = {
      currency: "USD",
      value: Number(totalValue ? totalValue.toFixed(2) : 0), // Sum of shipping price and protection cost
      shipping_tier: shippingTier || "Ground",
      items: items,
    };
    const optionsData = {
      currency: "USD",
      value: Number(totalValue ? totalValue.toFixed(2) : 0), // Sum of shipping price and protection cost
      items: items,
    };
    sendGTMEvent("add_shipping_info", ecommerceData);
    sendGTMEvent("choose_options", optionsData);
  } catch (error) {
    console.log(error);
  }
};

export const purchaseInfoLayer = (shipping, data) => {
  try {
    if (!shipping|| !data) return;
    //console.log(shipping,data)
    // Generate items with the shipping price
    const items = generateItems(data?.boxes, shipping?.[0]?.shippingRate);

    const optionsData = {
      currency: "USD",
      value: Number(shipping?.[0]?.subTotal ? shipping?.[0]?.subTotal.toFixed(2) : 0), // Sum of shipping price and protection cost
      items: items,
      payment_type: "Card",
    };
    sendGTMEvent("add_payment_info", optionsData);
  } catch (error) {
    console.log(error);
  }
};

export const purchaseLayer = (data) => {
  try {
    if (!data || !data.items) return;

    // Extract basic order details
    const { transaction_id, currency, value, items } = data;

    // 1. Calculate shipping price by summing up "Packages" price
    const shippingPrice = items
      ?.filter((item) => item.item_id === "Packages")
      .reduce((sum, item) => sum + (parseFloat(item.price) || 0), 0)
      .toFixed(2);

    // 2. Transform items based on the mapping rules
    const formattedItems = items?.map((item) => {
      if (item.item_id === "Packages") {
        return {
          item_id: "Shipping",
          item_name: "Shipping",
          price: parseFloat(item.price).toFixed(2),
          quantity: item.quantity,
        };
      } else if (item.item_id === "Protection") {
        return {
          item_id: "Insurance",
          item_name: "Insurance",
          price: parseFloat(item.price).toFixed(2),
          quantity: item.quantity,
        };
      }
      return item; // Keep other items unchanged
    });

    // 3. Construct the ecommerce data object
    const ecommerceData = {
      transaction_id,
      currency,
      value: parseFloat(value).toFixed(2),
      shipping: parseFloat(shippingPrice).toFixed(2),
      items: formattedItems,
    };

    // 4. Send GTM event
    sendGTMEvent("purchase", ecommerceData);
  } catch (error) {
    console.log(error);
  }
};

export const getQuoteLayer = (data) => {
  try {
    console.log(data);
    if (!data || !data.box) return;
    const { box } = data;
    const item = {
      item_id: "Shipping",
      item_name: "Shipping",
      item_category: box?.contents?.name || "Unknown",
      price: 0,
      market_value: 0,
      quantity: 1,
      length: box?.length,
      width: box?.width,
      height: box?.height,
      weight: box?.weight,
      case_model: box?.preset,
    };
    const ecommerceData = {
      currency: "USD",
      value: 0, // Order value is 0 at begin_checkout as specified.
      items: [item],
    };
    sendGTMEvent("get_quote", ecommerceData);
  } catch (error) {
    console.log(error);
  }
};
