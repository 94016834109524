import React, { useEffect, useRef, useState } from 'react'
import FormLayout from './FormLayout'
import useApp from '../store/useApp'
import { updateGuestUser, updateShipmentLabel } from '../helpers/apiRequests'
import { Formik } from 'formik'
import * as Yup from 'yup'
import StripeContainer from './Payments/StripeContainer'
import { getShipmentAddresses } from '../helpers/apiRequests'
import { purchaseInfoLayer } from '../helpers/gtm'

const initialValues = {
    name: '',
    email: '',
    phone_number: '',
    address: '',
    city: '',
    state: '',
    postalCode: ''
}

function Payments() {

    const {setLoading,shipmentId,clientSecret,labels,orderProcessed,setClientSecret,setLabels,setOrderProcessed,shipment,activeSearch,orderSummary} = useApp()
    const formRef = useRef(null)
    const [shipmentAddress,setShipmentAddress] = useState(null);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        email: Yup.string().email().required('Required'),
        phone_number: Yup.string().required('Required'),
        address: Yup.string().required('Required'),
        city: Yup.string().required('Required'),
        state: Yup.string().required('Required'),
        postalCode: Yup.string().required('Required'),
    })

    const handleShipmentAddressSelection = function(e){
        if(e.target.checked){
            if(shipmentAddress){
                formRef.current.setFieldValue('name',shipmentAddress.name);
                formRef.current.setFieldValue('phone_number',shipmentAddress.phone);
                formRef.current.setFieldValue('city',shipmentAddress.city);
                formRef.current.setFieldValue('state',shipmentAddress.state);
                formRef.current.setFieldValue('address',shipmentAddress.line);
                formRef.current.setFieldValue('postalCode',shipmentAddress.zipCode);
            }
        }
    }

    const handleFormSubmit = async function(values){
        const {name,email,address,city,state,postalCode} = values
        const obj = {
            name,email,address,city,state,postalCode,
            phone: values.phone_number,
            password: '',
            guestId: ''
        }
        setLoading(true)
        try {
            const {data} = await updateGuestUser(obj)
            console.log("Data",data,shipment,activeSearch)
            const {data:newData} = await updateShipmentLabel(shipmentId)
            const {paymentIntentId,userSecret} = newData
            setClientSecret(userSecret)
            console.log("NewData",paymentIntentId,userSecret);
            purchaseInfoLayer(orderSummary,activeSearch);
            setLoading(false)
        } catch (error) {
            console.log("Error",error)
            setLoading(false)
        }
    }

    const getAddresses = async function(){
        try{
           const {data:stages} = await getShipmentAddresses(shipmentId)
           stages?.forEach((stage)=>{
            if(stage?.addressStage === 1 && stage?.addressType === 'From'){
                setShipmentAddress(stage);
            }
           })
        }catch(error){
           console.log("Error",error)
        }
     }

    useEffect(()=>{
        setLoading(false)
        getAddresses();
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setOrderProcessed(false)
        setClientSecret(null)
    },[])

  return (
    <FormLayout>
        {!orderProcessed ? (<div>
        {<Formik
            innerRef={formRef}
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
        >{({values,errors,touched,setFieldValue,handleChange,handleSubmit})=>{
            console.log("Error",errors)
            return (
        <form onSubmit={handleSubmit} autocomplete="on" className="left-column basis-7/12 px-1 md:px-4 mb-3">
            <div className="ship-stage-wrapper mb-5">
                <div className="detail-stage stage-from">
                    <div className="font-bold text-[16px] text-[#00263A] mb-5 uppercase">Billing Address
                        <label className='input-group_checkbox text-[#808080]'>Same as Shipping Address
                            <input type='checkbox' name={`residential`} className='w-full text-base accent-[#00263A]' onChange={handleShipmentAddressSelection}/>
                            <span className='checkmark border border-[#00263A]'/>
                        </label>
                    </div>
                    <div className="flex flex-row justify-between md:gap-2 flex-wrap md:flex-nowrap">
                        <div className="relative mb-5 md:basis-1/2 basis-[100%] grow">
                            <input
                                id={`name`} type="text" autocomplete="name" maxlength="100" name={`name`} value={values.name} onChange={handleChange}
                                className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors.name && touched.name) && 'border !border-[#FF2853]'}`}
                                placeholder=" "
                            />
                            <label
                                htmlFor={`name`} 
                                className={`absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent ${(errors.name && touched.name) && '!text-[#FF2853]'}`}>
                                Name
                            </label>
                        </div>
                        <div className="relative mb-5 md:basis-1/2 basis-[100%] grow">
                            <input
                                id={`email`} type="email" autocomplete="email" maxlength="150" name={`email`} value={values.email} onChange={handleChange}
                                className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors.email && touched.email) && 'border !border-[#FF2853]'}`}
                                placeholder=" "
                            />
                            <label
                                htmlFor={`email`} 
                                className={`absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent ${(errors.email && touched.email) && '!text-[#FF2853]'}`}>
                                Email
                            </label>
                        </div>
                    </div>
                    <div className="relative mb-5">
                            <input
                                id={`address`} type="text" autocomplete="address" maxlength="100" name={`address`} value={values.address} onChange={handleChange}
                                className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors.address && touched.address) && 'border !border-[#FF2853]'}`}
                                placeholder=" "
                            />
                            <label
                                htmlFor={`address`} 
                                className={`absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent ${(errors.address && touched.address) && '!text-[#FF2853]'}`}>
                                Address
                            </label>
                    </div>
                    <div className="flex flex-row justify-between md:gap-2 flex-wrap md:flex-nowrap">
                        <div className="relative mb-5 md:basis-1/3 basis-[100%] grow">
                                <input
                                    id={`city`} type="text" autocomplete="city" maxlength="50" name={`city`} value={values.city} onChange={handleChange}
                                    className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors.city && touched.city) && 'border !border-[#FF2853]'}`}
                                    placeholder=" "
                                />
                                <label
                                    htmlFor={`city`} 
                                    className={`absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent ${(errors.city && touched.city) && '!text-[#FF2853]'}`}>
                                    City
                                </label>
                        </div>
                        <div className="relative mb-5 md:basis-1/3 basis-[100%] grow">
                                <input
                                    id={`state`} type="text" autocomplete="give-state" maxlength="50" name={`state`} value={values.state} onChange={handleChange}
                                    className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors.state && touched.state) && 'border !border-[#FF2853]'}`}
                                    placeholder=" "
                                />
                                <label
                                    htmlFor={`state`} 
                                    className={`absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent ${(errors.state && touched.state) && '!text-[#FF2853]'}`}>
                                    State
                                </label>
                        </div>
                        <div className="relative mb-5 md:basis-1/3 basis-[100%] grow">
                                <input
                                    id={`postalCode`} type="text" autocomplete="give-country" maxlength="50" name={`postalCode`} value={values.postalCode} onChange={handleChange}
                                    className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors.postalCode && touched.postalCode) && 'border !border-[#FF2853]'}`}
                                    placeholder=" "
                                />
                                <label
                                    htmlFor={`postalCode`} 
                                    className={`absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent ${(errors.postalCode && touched.postalCode) && '!text-[#FF2853]'}`}>
                                    Zip Code
                                </label>
                        </div>
                    </div>
                    <div className="relative mb-5">
                            <input
                                id={`phone_number`} type="text" autocomplete="phone" maxlength="200" name={`phone_number`} value={values.phone_number} onChange={handleChange}
                                className={`text-[#00263A] border peer block w-full appearance-none rounded-lg border-[#ccc] p-5 text-[14px] leading-[20px] text-medium focus:border-[#00263A] focus:outline-none focus:ring-0 ${(errors.phone_number && touched.phone_number) && 'border !border-[#FF2853]'}`}
                                placeholder=" "
                            />
                            <label
                                htmlFor={`phone_number`} 
                                className={`absolute text-[12px] text-medium leading-[16px] peer-focus:text-[12px] peer-focus:leading-[16px] px-[4px] left-5 peer-focus:left-5 text-[#808080] peer-focus:text-[#00263A] bg-white duration-300 transparent transform translate-y-[-50%] peer-focus:translate-y-[-50%] top-0 peer-focus:top-0 peer-placeholder-shown:top-[50%] peer-placeholder-shown:translate-y-[-50%] peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[20px] peer-placeholder-shown:text-[#808080] peer-focus:px-[4px]  peer-focus:transparent ${(errors.phone_number && touched.phone_number) && '!text-[#FF2853]'}`}>
                                Phone Number
                            </label>
                    </div>
                    <button aria-label="Buy" type="submit" className="button btn-reverse button--full-width custom-btn bg-[#ce0e2d]">
                        Continue
                    </button>
                </div>
            </div>
        </form>
        )}}
        </Formik>}
        {clientSecret && <StripeContainer clientSecret={clientSecret}/>}
        {/* <form autocomplete="on" className="left-column basis-7/12 px-1 md:px-4 mb-3">
            <div className="ship-stage-wrapper mb-5">
                <div className="detail-stage stage-from">
                    <div className="font-bold uppercase">Payment Form</div>
                    <div className="input-group mb-2">
                        <input name={`cart_number`} type="text" autocomplete="organization" maxlength="50" className="w-full custom-style-input text-base sm:text-sm"/> 
                        <label>Card Number</label>
                    </div>
                    <div className="flex flex-row justify-between">
                        <div className={`input-group mb-2 w-1/2 pr-1`}>
                            <input name={`expiration_date`} type="text" autocomplete="given-name" maxlength="50" className="w-full custom-style-input text-base sm:text-sm"/> 
                            <label>Expiration Date</label>
                        </div>
                        <div className={`input-group mb-2 w-1/2 pl-1`}>
                            <input name={`cvv`} type="text" autocomplete="family-name" maxlength="50" className="w-full custom-style-input text-base sm:text-sm"/> 
                            <label>CVV <em>(3 digits)</em></label>
                        </div>
                    </div>
                    <button aria-label="Buy" type="submit" className="uppercase font-semibold text-sm border-2 w-full p-3 rounded border-stone-400 text-stone-400 pointer-events-none">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="lock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="inline-block h-4 pb-0.5 svg-inline--fa fa-lock"><path fill="currentColor" d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" className=""></path></svg>
                        Complete Purchase
                    </button>
                </div>
            </div>
        </form> */}
        </div>) : (
            <div className='flex flex-col'>
            {labels?.map((label,i)=>{
                return  <div className='mb-2' key={i}>
                    <a href={'data:image/jpeg;base64,'+label} download={'jenson-bikes-label.png'}>Download</a>
                    <img src={'data:image/jpeg;base64,'+label}/>
                </div>
            })}
        </div>)}
    </FormLayout>
  )
}

export default Payments