import React, { useEffect, useRef, useState } from "react";
import Boxes from "./Boxes";
import AddressFields from "./AddressFields";
import Filters from "./Filters";
import useApp from "../store/useApp";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import {
  constructBoxesObjectForRates,
  constructTripsObjectForRates,
  convertPoundsKilograms,
  getSize,
} from "../helpers/generals";
import { shipmentInfoAnalytics } from "../helpers/gtagEvents";
import { beginCheckoutLayer } from "../helpers/gtm";

const weightMsg =
  "The weight you entered exceeds the Maximum Weight of 150 lbs (68 kg). If you ship a box or case that exceeds the Maximum Weight when weighed by our carrier, you will be charged a carrier penalty of US$1,025, your shipment may be stopped or returned, and your package may not be delivered. Please contact Support for packing suggestions to get your shipment’s weight down to or below 150 lbs (68 kg).";
const dimsMsg =
  "Your box dimensions exceed the maximum allowed. If you ship a box or case that exceeds the Maximum Size when scanned by our carrier, you will be charged a carrier penalty of US$1,325, your shipment may be stopped or returned, and your package may not be delivered. Please contact Support for packing suggestions to get your shipment’s size down to or below 160 in (406 cm) = (1 x Length) + (2 x Width) + (2 x Height).";

// For testing changes
// 1. Comment trips in 'setErrorMessages'
// 2. Comment trips in 'validationSchema'
// 3. Comment and set dummy data for 'tripsObj' in 'handleSubmit'

function SearchForm({ initialValues }) {
  const { setUnits, getTheRates, units, setAddresses } = useApp();
  const [errorMessage, setErrorMessage] = useState("");
  const [headsUp, setHeadsUp] = useState("");
  const [dimHeadsUp, setDimsHeadsUp] = useState("");
  const tripsHelperRef = useRef(null);
  const boxesHelperRef = useRef(null);

  const handleSubmit = function (values) {
    let boxesObj = constructBoxesObjectForRates(values.boxes);
    boxesObj.numOfPieces = values?.boxes?.length;
    let tripsObj = constructTripsObjectForRates(values.trips);
    // let tripsObj = [{
    //     to: {
    //       name: "",
    //       attentionName: "",
    //       address: {
    //         addressLine: [
    //           "NY 10025"
    //         ],
    //         city: "New York",
    //         stateProvinceCode: "NY",
    //         postalCode: "10025",
    //         countryCode: "US"
    //       }
    //     },
    //     from: {
    //       name: "",
    //       attentionName: "",
    //       address: {
    //         addressLine: [
    //           "MA 02631"
    //         ],
    //         city: "Brewster",
    //         stateProvinceCode: "MA",
    //         postalCode: "02631",
    //         countryCode: "US"
    //       }
    //     }
    // }]
    getTheRates(boxesObj, tripsObj, values.purpose, values);
    shipmentInfoAnalytics({ boxes: boxesObj, trips: tripsObj });
    beginCheckoutLayer(values);
  };

  const handleUnitsChange = function (value) {
    if (value === "1") {
      setUnits({ length: "cm", weight: "kg" });
    } else {
      setUnits({ length: "in", weight: "lb" });
    }
  };

  const setErrorMessages = function (values, errors) {
    values?.trips?.forEach((trip) => {
      if (trip?.from?.errorMessage) {
        setErrorMessage(trip.from.errorMessage);
      } else if (trip?.to?.errorMessage) {
        setErrorMessage(trip.to.errorMessage);
      } else {
        setErrorMessage("");
      }
    });
    errors?.boxes?.forEach((box) => {
      if (box?.length && box.length !== "Required") {
        setDimsHeadsUp(dimsMsg);
        return;
      } else if (box?.width && box.width !== "Required") {
        setDimsHeadsUp(dimsMsg);
        return;
      } else if (box?.height && box.height !== "Required") {
        setDimsHeadsUp(dimsMsg);
        return;
      }
      setDimsHeadsUp("");
    });

    errors?.boxes?.forEach((box) => {
      if (box?.weight && box.weight !== "Required") {
        setHeadsUp(weightMsg);
        return;
      }
      setHeadsUp("");
    });
  };

  const validationSchema = Yup.object().shape({
    trips: Yup.array().of(
      Yup.object().shape({
        from: Yup.object()
          .required("Required")
          .shape({
            invalid: Yup.bool().test(
              (value) => value === false || undefined || null
            ),
          }),
        to: Yup.object()
          .required("Required")
          .shape({
            invalid: Yup.bool().test(
              (value) => value === false || undefined || null
            ),
          }),
      })
    ),
    boxes: Yup.array().of(
      Yup.object().shape({
        length: Yup.number()
          .required("Required")
          .test((value, context) => {
            let size = getSize(
              value,
              context.parent?.width,
              context.parent?.height,
              units
            );
            return size > 160 ? false : true;
          }),
        width: Yup.number()
          .required("Required")
          .test((value, context) => {
            let size = getSize(
              context.parent?.length,
              value,
              context.parent?.height,
              units
            );
            return size > 160 ? false : true;
          }),
        height: Yup.number()
          .required("Required")
          .test((value, context) => {
            let size = getSize(
              context.parent?.length,
              context.parent?.width,
              value,
              units
            );
            return size > 160 ? false : true;
          }),
        weight: Yup.number()
          .required("Required")
          .test((value) => {
            let weight = value;
            if (units.weight === "kg")
              weight = convertPoundsKilograms("kg", "lb", value);
            return weight > 150 ? false : true;
          }),
        contents: Yup.object().required("Required"),
        marketValue: Yup.number().required("Required"),
        premiumProtection: Yup.object().required("Required"),
      })
    ),
  });

  return (
    <div className="sp-form">
      <div className="fields">
        {errorMessage !== "" && (
          <div id="1" className="notice relative mx-3 block danger mb-5">
            {/* <button aria-label="close" type="button" className="h-3 float-right ml-0.5 hover:opacity-75">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="h-3 svg-inline--fa fa-xmark"><path fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" className=""></path></svg>
                </button>  */}
            <strong>Sorry!</strong>
            <span>{" " + errorMessage}</span>
          </div>
        )}
        {headsUp !== "" && (
          <div id="1" className="notice relative mx-3 block danger mb-5">
            <strong>Heads Up!</strong>
            <span>{" " + headsUp}</span>
          </div>
        )}
        {dimHeadsUp !== "" && (
          <div id="1" className="notice relative mx-3 block danger mb-5">
            <strong>Heads Up!</strong>
            <span>{" " + dimHeadsUp}</span>
          </div>
        )}
        <Filters
          handleUnitsChange={handleUnitsChange}
          tripsHelperRef={tripsHelperRef}
          boxesHelperRef={boxesHelperRef}
        />
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleSubmit, setFieldValue, errors, touched }) => {
            setErrorMessages(values, errors);
            return (
              <form
                className="flex flex-col mt-2 mb-0"
                autoComplete="on"
                onSubmit={handleSubmit}
              >
                <FieldArray
                  name="trips"
                  render={(arrayHelpers) => {
                    tripsHelperRef.current = arrayHelpers;
                    return (
                      <div className="mb-1.5">
                        <div>
                          {values.trips.map((trip, index) => {
                            return (
                              <div key={trip.id}>
                                <p className="m-0 mb-4 font-bold text-black">
                                  SHIPMENT ADDRESSES
                                </p>
                                <div className="relative">
                                  {values.trips.length !== 1 && (
                                    <strong className="uppercase font-medium py-3">
                                      Stage {index + 1}
                                    </strong>
                                  )}
                                  <button
                                    aria-label="Remove stage"
                                    type="button"
                                    onClick={() => arrayHelpers.remove(index)}
                                    className={`cursor-pointer appearance-none h-3 right-0 mt-1 mr-1 hover:text-brand-500 absolute ${
                                      values.trips.length > 3 ? "" : "hidden"
                                    }`}
                                  >
                                    <svg
                                      aria-hidden="true"
                                      focusable="false"
                                      data-prefix="fas"
                                      data-icon="xmark"
                                      role="img"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 320 512"
                                      className="h-3 svg-inline--fa fa-xmark"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"
                                        className=""
                                      ></path>
                                    </svg>
                                  </button>
                                  <AddressFields
                                    errors={errors}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    item={trip}
                                    index={index}
                                  />
                                </div>
                              </div>
                            );
                          })}
                          {values.trips.length > 2 && (
                            <div>
                              <div className="ship-mode-buttons a-grid grid-cols-2 gap-2 w-full mb-5">
                                <button
                                  aria-label="Add stage"
                                  type="button"
                                  onClick={() =>
                                    values.trips.length < 6 &&
                                    arrayHelpers.push({
                                      id: Date.now(),
                                      from: "",
                                      to: "",
                                    })
                                  }
                                >
                                  <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="plus"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                    className="svg-inline--fa fa-plus"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                                      className=""
                                    ></path>
                                  </svg>{" "}
                                  Stage
                                </button>
                                <button
                                  aria-label="Remove stage"
                                  type="button"
                                  onClick={() =>
                                    values.trips.length > 3 &&
                                    arrayHelpers.pop()
                                  }
                                  disabled={values.trips.length < 4}
                                >
                                  <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="minus"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                    className="svg-inline--fa fa-minus"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"
                                      className=""
                                    ></path>
                                  </svg>{" "}
                                  Last Stage
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }}
                />
                <FieldArray
                  name="boxes"
                  render={(arrayHelpers) => {
                    boxesHelperRef.current = arrayHelpers;
                    return (
                      <>
                        {values.boxes.map((box, index) => {
                          return (
                            <Boxes
                              key={box.id}
                              box={box}
                              index={index}
                              setFieldValue={setFieldValue}
                              touched={touched}
                              errors={errors}
                            />
                          );
                        })}
                      </>
                    );
                  }}
                />
                <div className="field-group">
                  <div className="sp-field">
                    <input
                      type="submit"
                      name="Search"
                      className="button btn-reverse button-primary button--full bg-[#ce0e2d] custom-btn mt-3"
                    />
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default SearchForm;
