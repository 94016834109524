import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './store/AppContext';
import GetQuote from './GetQuote';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'G-PVJSWWVW1R', // Replace with your GTM ID
};

TagManager.initialize(tagManagerArgs);

const shippingForm = document.getElementById('shipping-form')
const bannerForm = document.getElementById('banner-form')

if(shippingForm){
const root = ReactDOM.createRoot(shippingForm);
root.render(
  <React.StrictMode>
    <AppProvider>
        <App />
    </AppProvider>
  </React.StrictMode>
);
}

if(bannerForm){
const bannerRoot = ReactDOM.createRoot(bannerForm);
bannerRoot.render(
  <React.StrictMode>
    <AppProvider>
      <GetQuote/>
    </AppProvider>
  </React.StrictMode>
);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
